import { type FC } from 'react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { PostListPieces } from '@/components/PostListPieces/PostListPieces'
import { router } from '@lifedot/router'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { PrefectureLinksList } from '@/components/PrefectureLinksList/PrefectureLinksList'
import { PickUpPosts } from './PickUpPosts/PickUpPosts'
import { MainVisual } from './MainVisual'
import { CategorySection } from './CategorySection'
import { topPage_indexQuery$data } from '@/queries/__generated__/topPage_indexQuery.graphql'
import { palette } from '@lifedot/styles/palette'
import { PopularAreaLinks } from './PopularAreaLinks/PopularAreaLinks'
import { useFetchshopLinkDisables } from './dependencies'

const styles = {
  h3: css({
    marginBottom: 4
  }),
  post: css({
    [mq('sp')]: {
      padding: '0 8px'
    }
  }),
  pickUp: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      gap: 12
    }
  }),
  prefectureLinks: css({
    backgroundColor: palette.white,
    padding: 40,
    [mq('sp')]: {
      padding: 20
    }
  })
}

interface TopContentsProps {
  data: topPage_indexQuery$data
}

export const TopContents: FC<TopContentsProps> = ({ data }) => {
  const shopLinkDisables = useFetchshopLinkDisables(data.prefLink.items)

  return (
    <AddMarginWrapper spacing={10}>
      <MainVisual />
      <CategorySection title="都道府県からお墓を探す" id="a-search">
        <div css={styles.prefectureLinks}>
          <PrefectureLinksList />
        </div>
      </CategorySection>
      <div css={styles.post}>
        <AddMarginWrapper spacing={10}>
          <CategorySection title="お墓について知る">
            <PostListPieces
              posts={data.ohakaPosts}
              moreLink={{
                href: router.post('ohaka'),
                label: 'お墓に関する記事一覧へ'
              }}
            />
          </CategorySection>
          <div css={styles.pickUp}>
            <section>
              <h3 css={[typography.textL, styles.h3]}>一般墓の関連記事</h3>
              <PickUpPosts posts={data.pickUpPosts1} />
            </section>
            <section>
              <h3 css={[typography.textL, styles.h3]}>樹木葬の関連記事</h3>
              <PickUpPosts posts={data.pickUpPosts2} />
            </section>
            <section>
              <h3 css={[typography.textL, styles.h3]}>納骨堂の関連記事</h3>
              <PickUpPosts posts={data.pickUpPosts3} />
            </section>
            <section>
              <h3 css={[typography.textL, styles.h3]}>永代供養墓の関連記事</h3>
              <PickUpPosts posts={data.pickUpPosts4} />
            </section>
            <section>
              <h3 css={[typography.textL, styles.h3]}>墓じまいの関連記事</h3>
              <PickUpPosts posts={data.pickUpPosts5} />
            </section>
          </div>
          <CategorySection title="全国の霊園について知る">
            <PostListPieces
              posts={data.cemeteryPosts}
              moreLink={{
                href: router.post('cemetery-top'),
                label: '霊園取材レポート一覧へ'
              }}
            />
          </CategorySection>
          <CategorySection title="葬儀について知る">
            <PostListPieces
              posts={data.sougiPosts}
              moreLink={{
                href: router.post('sougi'),
                label: '葬儀に関する記事一覧へ'
              }}
            />
          </CategorySection>
          <CategorySection title="仏壇・仏具について知る">
            <PostListPieces
              posts={data.butsudanPosts}
              moreLink={{
                href: router.post('butsudan'),
                label: '仏壇・仏具に関する記事一覧へ'
              }}
            />
          </CategorySection>
          <CategorySection title="相続について知る">
            <PostListPieces
              posts={data.souzokuPosts}
              moreLink={{
                href: router.post('souzoku'),
                label: '相続に関する記事一覧へ'
              }}
            />
          </CategorySection>
          <CategorySection title="生前準備・終活について知る">
            <PostListPieces
              posts={data.kaigoPosts}
              moreLink={{
                href: router.post('kaigo'),
                label: '生前準備・終活に関する記事一覧へ'
              }}
            />
          </CategorySection>
          <CategorySection title="人気エリアからお墓を探す">
            <PopularAreaLinks />
          </CategorySection>
          <CategorySection title="石材店・墓石販売店を探す">
            <div css={styles.prefectureLinks}>
              <PrefectureLinksList isShop disables={shopLinkDisables} />
            </div>
          </CategorySection>
        </AddMarginWrapper>
      </div>
    </AddMarginWrapper>
  )
}
