import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  manyColumns: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '6px 8px',
    marginTop: 4,
    [mq('sp')]: {
      gridTemplateColumns: 'repeat(5, 1fr)'
    }
  }),
  fewColumns: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '4px 8px',
    marginTop: 4,
    [mq('sp')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  }),
  disabledText: css({
    color: palette.gray5
  })
}

export interface GridLinkItemProps {
  name: string
  url: string
  disabled?: boolean
}

export interface GridLinksProps {
  items: GridLinkItemProps[]
  small?: boolean
}

export const GridLinks: FC<GridLinksProps> = ({ items, small = false }) => {
  return (
    <ul css={small ? styles.manyColumns : styles.fewColumns}>
      {items.map(({ name, url, disabled = false }) =>
        disabled ? (
          <li key={url} css={small ? typography.textS : typography.textM}>
            <span css={styles.disabledText}>{name}</span>
          </li>
        ) : (
          <li key={url} css={small ? typography.textS : typography.textM}>
            <a href={url}>{name}</a>
          </li>
        )
      )}
    </ul>
  )
}
