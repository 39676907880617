import { topPage_indexQuery$data } from '@/queries/__generated__/topPage_indexQuery.graphql'
import { useMemo } from 'react'
import { PrefectureLinksProps } from '../PrefectureLinksList/PrefectureLinks'

export const useFetchshopLinkDisables = (
  prefLink: topPage_indexQuery$data['prefLink']['items']
) => {
  return useMemo(
    () =>
      Object.fromEntries(
        prefLink.map(({ roma, shopCount }) => [roma, !shopCount])
      ) as PrefectureLinksProps['disables'],
    [prefLink]
  )
}
