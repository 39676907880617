/**
 * @generated SignedSource<<98b148e8d00ad009be52c1a788b04d21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PickUpPosts_posts$data = {
  readonly items: ReadonlyArray<{
    readonly slug: string
    readonly h1: string
    readonly image: {
      readonly url: string
      readonly alt: string | null
    } | null
  }>
  readonly ' $fragmentType': 'PickUpPosts_posts'
}
export type PickUpPosts_posts$key = {
  readonly ' $data'?: PickUpPosts_posts$data
  readonly ' $fragmentSpreads': FragmentRefs<'PickUpPosts_posts'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PickUpPosts_posts',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Post',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'slug',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'h1',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: 'PostImage',
          kind: 'LinkedField',
          name: 'image',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'url',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'alt',
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'PostResult',
  abstractKey: null
}

;(node as any).hash = 'e7578cca103eebf6d5cdf819ca7ceed7'

export default node
