import { prefectures } from '@lifedot/constants/prefectures'

const jumoku = [
  {
    name: prefectures.hokkaido,
    prefecture: 'hokkaido'
  },
  {
    name: prefectures.iwate,
    prefecture: 'iwate'
  },
  {
    name: prefectures.miyagi,
    prefecture: 'miyagi'
  },
  {
    name: prefectures.gunma,
    prefecture: 'gunma'
  },
  {
    name: prefectures.tochigi,
    prefecture: 'tochigi'
  },
  {
    name: prefectures.ibaraki,
    prefecture: 'ibaraki'
  },
  {
    name: prefectures.saitama,
    prefecture: 'saitama'
  },
  {
    name: prefectures.chiba,
    prefecture: 'chiba'
  },
  {
    name: prefectures.tokyo,
    prefecture: 'tokyo'
  },
  {
    name: prefectures.kanagawa,
    prefecture: 'kanagawa'
  },
  {
    name: prefectures.yamanashi,
    prefecture: 'yamanashi'
  },
  {
    name: prefectures.aichi,
    prefecture: 'aichi'
  },
  {
    name: prefectures.shizuoka,
    prefecture: 'shizuoka'
  },
  {
    name: prefectures.shiga,
    prefecture: 'shiga'
  },
  {
    name: prefectures.mie,
    prefecture: 'mie'
  },
  {
    name: prefectures.hyogo,
    prefecture: 'hyogo'
  },
  {
    name: prefectures.nara,
    prefecture: 'nara'
  },
  {
    name: prefectures.osaka,
    prefecture: 'osaka'
  },
  {
    name: prefectures.hiroshima,
    prefecture: 'hiroshima'
  },
  {
    name: prefectures.okayama,
    prefecture: 'okayama'
  },
  {
    name: prefectures.fukuoka,
    prefecture: 'fukuoka'
  },
  {
    name: prefectures.saga,
    prefecture: 'saga'
  },
  {
    name: 'さいたま市',
    prefecture: 'saitama',
    city: 526 //さいたま市
  },
  {
    name: '川口市',
    prefecture: 'saitama',
    city: 539 //川口市
  },
  {
    name: '松戸市',
    prefecture: 'chiba',
    city: 611 //松戸市
  },
  {
    name: '市川市',
    prefecture: 'chiba',
    city: 607 //市川市
  },
  {
    name: '船橋市',
    prefecture: 'chiba',
    city: 608 //船橋市
  },
  {
    name: '横浜市',
    prefecture: 'kanagawa',
    city: 721 //横浜市
  },
  {
    name: '町田市',
    prefecture: 'tokyo',
    city: 690 //町田市
  },
  {
    name: '八王子市',
    prefecture: 'tokyo',
    city: 682 //八王子市
  },
  {
    name: '葛飾区',
    prefecture: 'tokyo',
    city: 680 //葛飾区
  },
  {
    name: '足立区',
    prefecture: 'tokyo',
    city: 679 //足立区
  },
  {
    name: '台東区',
    prefecture: 'tokyo',
    city: 664 //台東区
  },
  {
    name: '世田谷区',
    prefecture: 'tokyo',
    city: 670 //世田谷区
  },
  {
    name: '高崎市',
    prefecture: 'gunma',
    city: 492 //高崎市
  },
  {
    name: '名古屋市',
    prefecture: 'aichi',
    city: 1062 //名古屋市
  },
  {
    name: '神戸市',
    prefecture: 'hyogo',
    city: 1291 //神戸市
  },
  {
    name: '大阪市',
    prefecture: 'osaka',
    city: 1217 //大阪市
  }
]

const nokotsu = [
  {
    name: prefectures.hokkaido,
    prefecture: 'hokkaido'
  },
  {
    name: prefectures.miyagi,
    prefecture: 'miyagi'
  },
  {
    name: prefectures.gunma,
    prefecture: 'gunma'
  },
  {
    name: prefectures.ibaraki,
    prefecture: 'ibaraki'
  },
  {
    name: prefectures.saitama,
    prefecture: 'saitama'
  },
  {
    name: prefectures.chiba,
    prefecture: 'chiba'
  },
  {
    name: prefectures.tokyo,
    prefecture: 'tokyo'
  },
  {
    name: prefectures.kanagawa,
    prefecture: 'kanagawa'
  },
  {
    name: prefectures.yamanashi,
    prefecture: 'yamanashi'
  },
  {
    name: prefectures.ishikawa,
    prefecture: 'ishikawa'
  },
  {
    name: prefectures.aichi,
    prefecture: 'aichi'
  },
  {
    name: prefectures.shizuoka,
    prefecture: 'shizuoka'
  },
  {
    name: prefectures.shiga,
    prefecture: 'shiga'
  },
  {
    name: prefectures.mie,
    prefecture: 'mie'
  },
  {
    name: prefectures.hyogo,
    prefecture: 'hyogo'
  },
  {
    name: prefectures.nara,
    prefecture: 'nara'
  },
  {
    name: prefectures.osaka,
    prefecture: 'osaka'
  },
  {
    name: prefectures.hiroshima,
    prefecture: 'hiroshima'
  },
  {
    name: prefectures.okayama,
    prefecture: 'okayama'
  },
  {
    name: prefectures.fukuoka,
    prefecture: 'fukuoka'
  },
  {
    name: prefectures.okinawa,
    prefecture: 'okinawa'
  },
  {
    name: '札幌市',
    prefecture: 'hokkaido',
    city: 1 //札幌市
  },
  {
    name: '千葉市',
    prefecture: 'chiba',
    city: 599 //千葉市
  },
  {
    name: '市川市',
    prefecture: 'chiba',
    city: 607 //市川市
  },
  {
    name: '横浜市',
    prefecture: 'kanagawa',
    city: 721 //横浜市
  },
  {
    name: '川崎市',
    prefecture: 'kanagawa',
    city: 740 //川崎市
  },
  {
    name: '八王子市',
    prefecture: 'tokyo',
    city: 682 //八王子市
  },
  {
    name: '江戸川区',
    prefecture: 'tokyo',
    city: 681 //江戸川区
  },
  {
    name: '台東区',
    prefecture: 'tokyo',
    city: 664 //台東区
  },
  {
    name: '墨田区',
    prefecture: 'tokyo',
    city: 665 //墨田区
  },
  {
    name: '世田谷区',
    prefecture: 'tokyo',
    city: 670 //世田谷区
  },
  {
    name: '名古屋市',
    prefecture: 'aichi',
    city: 1062 //名古屋市
  },
  {
    name: '神戸市',
    prefecture: 'hyogo',
    city: 1291 //神戸市
  },
  {
    name: '大阪市',
    prefecture: 'osaka',
    city: 1217 //大阪市
  },
  {
    name: '堺市',
    prefecture: 'osaka',
    city: 1242 //堺市
  },
  {
    name: '高槻市',
    prefecture: 'osaka',
    city: 1255 //高槻市
  },
  {
    name: '北九州市',
    prefecture: 'fukuoka',
    city: 1624 //北九州市
  }
]

const eitai_kuyo = [
  {
    name: prefectures.hokkaido,
    prefecture: 'hokkaido'
  },
  {
    name: prefectures.iwate,
    prefecture: 'iwate'
  },
  {
    name: prefectures.miyagi,
    prefecture: 'miyagi'
  },
  {
    name: prefectures.gunma,
    prefecture: 'gunma'
  },
  {
    name: prefectures.tochigi,
    prefecture: 'tochigi'
  },
  {
    name: prefectures.ibaraki,
    prefecture: 'ibaraki'
  },
  {
    name: prefectures.saitama,
    prefecture: 'saitama'
  },
  {
    name: prefectures.chiba,
    prefecture: 'chiba'
  },
  {
    name: prefectures.tokyo,
    prefecture: 'tokyo'
  },
  {
    name: prefectures.kanagawa,
    prefecture: 'kanagawa'
  },
  {
    name: prefectures.yamanashi,
    prefecture: 'yamanashi'
  },
  {
    name: prefectures.ishikawa,
    prefecture: 'ishikawa'
  },
  {
    name: prefectures.nagano,
    prefecture: 'nagano'
  },
  {
    name: prefectures.aichi,
    prefecture: 'aichi'
  },
  {
    name: prefectures.shizuoka,
    prefecture: 'shizuoka'
  },
  {
    name: prefectures.shiga,
    prefecture: 'shiga'
  },
  {
    name: prefectures.mie,
    prefecture: 'mie'
  },
  {
    name: prefectures.hyogo,
    prefecture: 'hyogo'
  },
  {
    name: prefectures.nara,
    prefecture: 'nara'
  },
  {
    name: prefectures.osaka,
    prefecture: 'osaka'
  },
  {
    name: prefectures.hiroshima,
    prefecture: 'hiroshima'
  },
  {
    name: prefectures.okayama,
    prefecture: 'okayama'
  },
  {
    name: prefectures.ehime,
    prefecture: 'ehime'
  },
  {
    name: prefectures.kagawa,
    prefecture: 'kagawa'
  },
  {
    name: prefectures.fukuoka,
    prefecture: 'fukuoka'
  },
  {
    name: prefectures.saga,
    prefecture: 'saga'
  },
  {
    name: prefectures.okinawa,
    prefecture: 'okinawa'
  },
  {
    name: '札幌市',
    prefecture: 'hokkaido',
    city: 1 //札幌市
  },
  {
    name: '仙台市',
    prefecture: 'miyagi',
    city: 263 //仙台市
  },
  {
    name: 'さいたま市',
    prefecture: 'saitama',
    city: 526 //さいたま市
  },
  {
    name: '川口市',
    prefecture: 'saitama',
    city: 539 //川口市
  },
  {
    name: '千葉市',
    prefecture: 'chiba',
    city: 599 //千葉市
  },
  {
    name: '佐倉市',
    prefecture: 'chiba',
    city: 615 //佐倉市
  },
  {
    name: '市原市',
    prefecture: 'chiba',
    city: 621 //市原市
  },
  {
    name: '松戸市',
    prefecture: 'chiba',
    city: 611 //松戸市
  },
  {
    name: '柏市',
    prefecture: 'chiba',
    city: 619 //柏市
  },
  {
    name: '市川市',
    prefecture: 'chiba',
    city: 607 //市川市
  },
  {
    name: '船橋市',
    prefecture: 'chiba',
    city: 608 //船橋市
  },
  {
    name: '横浜市',
    prefecture: 'kanagawa',
    city: 721 //横浜市
  },
  {
    name: '川崎市',
    prefecture: 'kanagawa',
    city: 740 //川崎市
  },
  {
    name: '相模原市',
    prefecture: 'kanagawa',
    city: 748 //相模原市
  },
  {
    name: '町田市',
    prefecture: 'tokyo',
    city: 690 //町田市
  },
  {
    name: '八王子市',
    prefecture: 'tokyo',
    city: 682 //八王子市
  },
  {
    name: '葛飾区',
    prefecture: 'tokyo',
    city: 680 //葛飾区
  },
  {
    name: '江戸川区',
    prefecture: 'tokyo',
    city: 681 //江戸川区
  },
  {
    name: '足立区',
    prefecture: 'tokyo',
    city: 679 //足立区
  },
  {
    name: '台東区',
    prefecture: 'tokyo',
    city: 664 //台東区
  },
  {
    name: '江東区',
    prefecture: 'tokyo',
    city: 666 //江東区
  },
  {
    name: '大田区',
    prefecture: 'tokyo',
    city: 669 //大田区
  },
  {
    name: '墨田区',
    prefecture: 'tokyo',
    city: 665 //墨田区
  },
  {
    name: '世田谷区',
    prefecture: 'tokyo',
    city: 670 //世田谷区
  },
  {
    name: '板橋区',
    prefecture: 'tokyo',
    city: 677 //板橋区
  },
  {
    name: '新潟市',
    prefecture: 'niigata',
    city: 782 //新潟市
  },
  {
    name: '高崎市',
    prefecture: 'gunma',
    city: 492 //高崎市
  },
  {
    name: '津市',
    prefecture: 'mie',
    city: 1132 //津市
  },
  {
    name: '名古屋市',
    prefecture: 'aichi',
    city: 1062 //名古屋市
  },
  {
    name: '大津市',
    prefecture: 'shiga',
    city: 1161 //大津市
  },
  {
    name: '神戸市',
    prefecture: 'hyogo',
    city: 1291 //神戸市
  },
  {
    name: '大阪市',
    prefecture: 'osaka',
    city: 1217 //大阪市
  },
  {
    name: '堺市',
    prefecture: 'osaka',
    city: 1242 //堺市
  },
  {
    name: '高槻市',
    prefecture: 'osaka',
    city: 1255 //高槻市
  },
  {
    name: '東広島市',
    prefecture: 'hiroshima',
    city: 1497 //東広島市
  },
  {
    name: '倉敷市',
    prefecture: 'okayama',
    city: 1453 //倉敷市
  },
  {
    name: '松山市',
    prefecture: 'ehime',
    city: 1570 //松山市
  }
]

export const popularAreaLink = {
  jumoku: jumoku,
  nokotsu: nokotsu,
  eitai_kuyo: eitai_kuyo
}
