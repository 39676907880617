import { ComponentProps, FC, ReactNode } from 'react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'

const styles = {
  h2: css({
    textAlign: 'center',
    marginBottom: 20,
    '::after': {
      content: '""',
      display: 'block',
      borderBottom: `2px solid ${palette.main}`,
      width: 60,
      margin: '12px auto 0'
    }
  })
}

interface CategorySectionProps extends ComponentProps<'section'> {
  title: string
  children: ReactNode
}

export const CategorySection: FC<CategorySectionProps> = ({
  title,
  children,
  ...rest
}) => {
  return (
    <section {...rest}>
      <h2 css={[typography.headingM, styles.h2]}>{title}</h2>
      {children}
    </section>
  )
}
