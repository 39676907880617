import { FC } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { router } from '@lifedot/router'
import Link from 'next/link'
import { PostCard } from '@lifedot/components/PostCard'
import { PickUpPosts_posts$key } from './__generated__/PickUpPosts_posts.graphql'

// TODO: libsに移動して1列と2列を出しわけられるコンポーネントにする

const styles = {
  root: css({
    marginBottom: -8
  }),
  link: css({
    display: 'block',
    textDecoration: 'none',
    marginBottom: 8
  })
}

interface PickUpPostsProps {
  posts: PickUpPosts_posts$key
}

export const PickUpPosts: FC<PickUpPostsProps> = ({ posts }) => {
  const data = useFragment(fragment, posts)

  return (
    <div css={styles.root}>
      {data.items.map(({ h1, slug, image }) => (
        <Link key={slug} href={router.post(slug)} prefetch={false} passHref>
          <a css={styles.link}>
            <PostCard
              title={h1}
              thumbnailUrl={image?.url}
              thumbnailAlt={image?.alt}
              horizontal
            />
          </a>
        </Link>
      ))}
    </div>
  )
}

const fragment = graphql`
  fragment PickUpPosts_posts on PostResult {
    items {
      slug
      h1
      image {
        url
        alt
      }
    }
  }
`
