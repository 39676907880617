import { graphql } from 'relay-runtime'

export const topPageIndexQuery = graphql`
  query topPage_indexQuery {
    ohakaPosts: listPosts(
      filter: { parent_slugs: ["ohaka"] }
      page: { from: 0, size: 4 }
    ) {
      ...PostListPieces_posts
    }
    sougiPosts: listPosts(
      filter: { parent_slugs: ["sougi"] }
      page: { from: 0, size: 4 }
    ) {
      ...PostListPieces_posts
    }
    butsudanPosts: listPosts(
      filter: { parent_slugs: ["butsudan"] }
      page: { from: 0, size: 4 }
    ) {
      ...PostListPieces_posts
    }
    souzokuPosts: listPosts(
      filter: { parent_slugs: ["souzoku"] }
      page: { from: 0, size: 4 }
    ) {
      ...PostListPieces_posts
    }
    kaigoPosts: listPosts(
      filter: { parent_slugs: ["kaigo"] }
      page: { from: 0, size: 4 }
    ) {
      ...PostListPieces_posts
    }
    cemeteryPosts: listPosts(
      filter: { parent_slugs: ["cemetery-top"] }
      page: { from: 0, size: 4 }
    ) {
      ...PostListPieces_posts
    }
    pickUpPosts1: listPosts(
      filter: { slugs: ["ohaka", "ohaka-price", "ohaka-search"] }
    ) {
      ...PickUpPosts_posts
    }
    pickUpPosts2: listPosts(
      filter: { slugs: ["jumokusou", "jumokusou-price", "jumokusou-kakuyasu"] }
    ) {
      ...PickUpPosts_posts
    }
    pickUpPosts3: listPosts(
      filter: { slugs: ["noukotsudou", "noukotsudou-price", "ohaka-mansion"] }
    ) {
      ...PickUpPosts_posts
    }
    pickUpPosts4: listPosts(
      filter: {
        slugs: ["eitaikuyou-meaning", "eitaikuyou-price", "ohaka-nothing"]
      }
    ) {
      ...PickUpPosts_posts
    }
    pickUpPosts5: listPosts(
      filter: {
        slugs: ["ohaka-jimai-price", "ohaka-kaisou", "eitaikuyou-hakajimai"]
      }
    ) {
      ...PickUpPosts_posts
    }
    newlyPosts: listPosts(
      filter: { template_name: "show_post" }
      page: { from: 0, size: 5 }
    ) {
      ...PostListCard_posts
    }
    newlyCemeteryPosts: listPosts(
      filter: { parent_slugs: ["ohaka"] }
      page: { from: 0, size: 5 }
    ) {
      ...PostListCard_posts
    }
    prefLink: listPrefecturesWithShopCount(limit: 99) {
      items {
        name
        roma
        shopCount
      }
      totalShopCount
    }
  }
`
