import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { PostListPieces_posts$key } from './__generated__/PostListPieces_posts.graphql'
import { PostCard } from '@lifedot/components/PostCard'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { elevation } from '@lifedot/styles/elevation'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = {
  root: css({
    display: 'flex',
    flexWrap: 'wrap',
    [mq('sp')]: {
      justifyContent: 'space-between'
    }
  }),
  link: css({
    width: 'calc(50% - 8px)',
    display: 'flex',
    textDecoration: 'none',
    marginRight: 8,
    marginBottom: 8,
    [mq('sp')]: {
      width: 'calc(50% - 4px)',
      marginRight: 0
    }
  }),
  moreLink: css({
    border: `1px solid ${palette.gray2}`,
    backgroundColor: palette.white,
    padding: 12,
    borderRadius: 40,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    margin: '12px auto 0',
    boxShadow: elevation[1],
    color: palette.gray6,
    fontWeight: 'bold',
    [mq('sp')]: {
      width: '80%',
      padding: 8
    }
  })
}

interface PostListPiecesProps {
  posts: PostListPieces_posts$key
  moreLink?: { href: string; label: string }
}

export const PostListPieces: FC<PostListPiecesProps> = ({
  posts,
  moreLink
}) => {
  const data = useFragment(fragment, posts)

  return (
    <>
      <div css={styles.root}>
        {data.items.map(({ h1, slug, image, modified_at }) => (
          <Link key={slug} href={router.post(slug)} prefetch={false} passHref>
            <a css={styles.link}>
              <PostCard
                title={h1}
                thumbnailUrl={image?.url}
                thumbnailAlt={image?.alt}
                lastUpdate={modified_at}
              />
            </a>
          </Link>
        ))}
      </div>
      {moreLink && (
        <Link href={moreLink.href} passHref prefetch={false}>
          <a css={[typography.textS, styles.moreLink]}>
            {moreLink.label}
            <ChevronRightIcon fontSize="small" />
          </a>
        </Link>
      )}
    </>
  )
}

const fragment = graphql`
  fragment PostListPieces_posts on PostResult {
    items {
      slug
      h1
      image {
        url
        alt
      }
      modified_at
    }
  }
`
