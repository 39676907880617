import type { FC } from 'react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { css } from '@emotion/react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { GridLinks } from '@lifedot/components/GridLinks'
import { useMakePopularAreaLink } from './dependencies'

const styles = {
  linkwrap: css({
    background: palette.white,
    padding: '12px 40px',
    [mq('sp')]: {
      padding: 12
    }
  })
}

export const PopularAreaLinks: FC = () => {
  const itemsData = [
    { title: '樹木葬', items: useMakePopularAreaLink('jumoku') },
    { title: '納骨堂', items: useMakePopularAreaLink('nokotsu') },
    { title: '永代供養墓', items: useMakePopularAreaLink('eitai_kuyo') }
  ]

  return (
    <AddMarginWrapper spacing={5}>
      {itemsData.map(({ title, items }) => (
        <div key={title} css={styles.linkwrap}>
          <h3>人気エリアの{title}を探す</h3>
          <GridLinks items={items} small />
        </div>
      ))}
    </AddMarginWrapper>
  )
}
