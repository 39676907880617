import { FC } from 'react'
import { OrganizationJsonLd } from '@lifedot/components/StructuredData'
import { markup } from '@lifedot/utils'
import { siteDescription, siteTitle } from '@/constants'

export const TopStructuredData: FC = () => {
  return (
    <>
      <OrganizationJsonLd data-cy="structured-data-organization" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(siteNavigationElement)}
        data-cy="structured-data-site-navigation-element"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(webSite)}
        data-cy="structured-data-web-site"
      />
    </>
  )
}

const siteNavigationElement = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'SiteNavigationElement',
  name: [
    'お墓を探す',
    'お墓について',
    '葬儀について',
    '仏壇・仏具について',
    '相続について',
    '生前準備・終活について'
  ],
  url: [
    'https://www.lifedot.jp/#a-search',
    'https://www.lifedot.jp/ohaka/',
    'https://www.lifedot.jp/sougi/',
    'https://www.lifedot.jp/butsudan/',
    'https://www.lifedot.jp/souzoku/',
    'https://www.lifedot.jp/kaigo/'
  ]
})

const webSite = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'ライフドット',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.lifedot.jp'
  },
  headline: siteTitle,
  description: siteDescription
})
