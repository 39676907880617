import { GridLinkItemProps } from '@lifedot/components/GridLinks'
import { popularAreaLink } from './popularAreaLink'
import { router } from '@lifedot/router'
import { useMemo } from 'react'

export const useMakePopularAreaLink = (
  sectionType: 'jumoku' | 'nokotsu' | 'eitai_kuyo'
): Array<GridLinkItemProps> => {
  return useMemo(() => {
    return popularAreaLink[sectionType].map(({ name, prefecture, city }) => ({
      name: name,
      url: router.list({
        prefectureRoma: prefecture,
        cityId: city,
        condition: {
          attributeCode: 'section_type',
          code: sectionType
        }
      })
    }))
  }, [sectionType])
}
