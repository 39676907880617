import { GetStaticProps } from 'next'
import { FC } from 'react'
import { initEnvironment } from '@lifedot/relay'
import { fetchQuery } from 'relay-runtime'
import { topPage_indexQuery } from '@/queries/__generated__/topPage_indexQuery.graphql'
import { topPageIndexQuery } from '@/queries/topPage'
import { useQuery } from 'relay-hooks/lib/useQuery'
import { NotFound } from '@lifedot/components/NotFound'
import { MainColumn, MultiColumns, SubColumn } from '@lifedot/layout'
import { Banners } from '@lifedot/components/Banners'
import { TopSeoHead } from '@/components/Seo/top/TopSeoHead'
import { PostListCard } from '@/components/PostListCard/PostListCard'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { TopStructuredData } from '@/components/StructuredData/TopStructuredData/TopStructuredData'
import { AreaLinkAccordions } from '@/components/AreaLinkAccordions/AreaLinkAccordions'
import { PostDummyTemplate } from '@/templates/PostTemplate/PostDummyTemplate'
import { TopContents } from '@/components/TopContents/TopContents'

export const getStaticProps: GetStaticProps = async () => {
  try {
    const { environment, relaySSR }: ReturnType<typeof initEnvironment> =
      initEnvironment()

    await fetchQuery<topPage_indexQuery>(
      environment,
      topPageIndexQuery,
      {}
    ).toPromise()

    const [relayData] = await relaySSR.getCache()

    const [cacheString, payload] = relayData

    return {
      props: {
        relayData:
          !relayData || !('json' in payload)
            ? null
            : [[cacheString, payload.json]]
      },
      revalidate: Number(process.env.DEFAULT_REVALIDATE_TERM)
    }
  } catch (e) {
    if (typeof e === 'object' && e !== null && 'res' in e) {
      if (typeof (e as { res?: unknown }).res === 'object')
        console.log((e as { res?: { errors?: unknown } }).res?.errors)
    } else if (e instanceof Error) console.log(e.message)

    return {
      props: {},
      revalidate: 1
    }
  }
}

const TopPage: FC = () => {
  const { error, data, isLoading } = useQuery<topPage_indexQuery>(
    topPageIndexQuery,
    {}
  )

  if (isLoading) return <PostDummyTemplate />
  if (error || !data) return <NotFound />

  return (
    <>
      <TopSeoHead />
      <TopStructuredData />
      <MultiColumns>
        <MainColumn>
          <TopContents data={data} />
        </MainColumn>
        <SubColumn>
          <AddMarginWrapper spacing={3}>
            <Banners />
            <AreaLinkAccordions />
            <PostListCard
              posts={data.newlyCemeteryPosts}
              header="お墓の新着記事"
            />
            <PostListCard posts={data.newlyPosts} header="新着記事" />
          </AddMarginWrapper>
        </SubColumn>
      </MultiColumns>
    </>
  )
}

export default TopPage
