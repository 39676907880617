import { FC } from 'react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { css } from '@emotion/react'
import Image from 'next/image'
import { rgba } from 'emotion-rgba'

const styles = {
  mainVisual: css({
    position: 'relative',
    height: 260,
    '::after': {
      content: '""',
      backgroundColor: rgba(palette.black, 0.1),
      backgroundBlendMode: 'multiply',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    [mq('sp')]: {
      height: 160
    }
  }),
  mainTextBox: css({
    color: palette.white,
    position: 'absolute',
    top: 44,
    left: 40,
    fontWeight: 'bold',
    textShadow: `0 0 8px ${rgba(palette.black, 0.8)}`,
    zIndex: 1,
    [mq('sp')]: {
      top: 22,
      left: 20
    },
    '::before': {
      content: '""',
      border: `2px solid ${palette.main}`,
      width: 120,
      position: 'absolute',
      top: -14,
      left: -40,
      [mq('sp')]: {
        top: -8,
        left: -20
      }
    }
  }),
  mainText: css({
    fontSize: 44,
    letterSpacing: 2,
    [mq('sp')]: {
      fontSize: 28
    }
  }),
  subText: css({
    fontSize: 20,
    marginTop: 12,
    display: 'block',
    letterSpacing: 1,
    [mq('sp')]: {
      fontSize: 16
    }
  })
}

export const MainVisual: FC = () => {
  return (
    <div css={styles.mainVisual}>
      <Image
        src="https://s3.lifedot.jp/uploads/ending/path/67807/top_key_visual.jpg"
        alt="家族の後ろ姿"
        priority
        layout="fill"
        objectFit="cover"
      />
      <div css={styles.mainTextBox}>
        <p css={styles.mainText}>
          自分にあった
          <br />
          お墓を見つける
        </p>
        <span css={styles.subText}>お墓探しはライフドット</span>
      </div>
    </div>
  )
}
