import { FC } from 'react'
import { urlify } from '@lifedot/router'
import { NextSeo } from 'next-seo'
import { siteDescription, siteTitle } from '@/constants'

const url = urlify('/')
const props = {
  title: siteTitle,
  description: siteDescription,
  additionalMetaTags: [{ name: 'keywords', content: 'お墓,葬儀,仏壇,終活' }],
  openGraph: {
    title: siteTitle,
    url,
    description: siteDescription,
    images: [
      { url: 'https://s3.lifedot.jp/uploads/ending/path/63853/og_image.png' }
    ]
  },
  canonical: url
}

export const TopSeoHead: FC = () => {
  return <NextSeo {...props} />
}
